import React, { useState, useEffect } from "react";
import urls from "../../../areas/main/assets/urls";
import Texts, { currentLang, getMenuMockupImage, getQrDemoVideo } from "../../../utils/lang-utils";


const Banner = () => {

  const [autoPlay, setAutoPlay] = useState(false);
  useEffect(() => {
    setAutoPlay(window.innerWidth >= 992 && window.innerWidth <= 1200);
    document.onscroll = () => document.querySelector('#qr-ordering-demo').play();
  }, []);

  return (
    <section className="banner-one" id="banner">
      <div className="container">
        
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="banner-one__content">
              <h1 className="banner-one__title" dangerouslySetInnerHTML={{__html: Texts.QROrdering.IntroTitle}} />

              <h2 style={{ fontWeight: "normal"}} className="banner-one__text" dangerouslySetInnerHTML={{__html: Texts.QROrdering.IntroText}} />
              <a href={urls.onboarding} className="banner-one__btn thm-btn ">
                <span>{Texts.introCTA}</span>
              </a>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
              <div className="banner-one__moc qr-ordering ">
                <img className="menu-moc" src={getMenuMockupImage()} />
              
                <div className={`video ${currentLang}`}>
                <div className="qr-container1 fadeIn" />
                <div className="qr-container2 fadeIn" />
                <div className="pulse1" />
                <a className="play fadeIn" />
                <video muted autoPlay={autoPlay} id="qr-ordering-demo" playsInline loop>
                    <source src={getQrDemoVideo()} type="video/mp4"></source>
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;
